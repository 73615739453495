import { UserMigrationDefaults } from '@local/api/documents'
import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { useUserMigration } from '../UserMigrationContextProvider'
import { useApolloClient } from '@apollo/client'
import { useDeferMigrationMutation } from '../../hooks'
import { StartView } from './StartView'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import { useUser } from '@toasttab/ec-session'
import { useFeature } from '@toasttab/ec-features'
import { logout } from '../redirects'

export const Start = () => {
  const client = useApolloClient()
  const { state, dispatch } = useUserMigration()
  const { mutate: deferMigration } = useDeferMigrationMutation()
  const urlParams = new URLSearchParams(window.location.search)
  const returnUrl = urlParams.get('returnUrl')
  const [loading, setLoading] = React.useState(false)
  const { showErrorSnackBar } = useSnackBar()
  const { t } = useTranslation()
  const user = useUser()

  useEffect(() => {
    if (returnUrl && state.returnUrl !== returnUrl) {
      dispatch({ type: 'set-return-url', returnUrl: returnUrl })
    }
  }, [returnUrl])

  const handleContinue = useCallback(() => {
    const queryDefaults = async () => {
      setLoading(true)
      const result = await client.query({
        query: UserMigrationDefaults
      })

      const defaults = result.data?.userMigration?.defaults

      if (result.error) {
        showErrorSnackBar(t('anErrorOccurred'))
      }

      switch (defaults?.__typename) {
        case 'UserMigrationDefaults':
          dispatch({
            type: 'migration-defaults',
            email: defaults?.email || undefined,
            source: defaults?.source || 'NEW',
            canChangeEmail: defaults ? defaults.canChangeEmail : true
          })
          break
        case 'UserMigrationUserAlreadyMigrated':
          dispatch({
            type: 'migrated',
            userId: defaults?.userId,
            toastIdentityGuid: defaults?.toastIdentityGuid,
            email: user.email
          })
          break
        case 'UserMigrationNotEnabled':
          logout()
          break
        case 'UserMigrationUserNotFound':
          showErrorSnackBar(t('anErrorOccurred'))
          dispatch({
            type: 'restart'
          })
          break
        case 'UserMigrationFailed':
          showErrorSnackBar(t('anErrorOccurred'))
          break
        default:
          showErrorSnackBar(t('anErrorOccurred'))
      }
      setLoading(false)
    }
    queryDefaults()
  }, [client, dispatch, showErrorSnackBar])

  const handleDeferMigration = () => {
    deferMigration(
      { returnUrl },
      {
        onSuccess: (data) => {
          if (data?.status === 403) {
            showErrorSnackBar(t('anErrorOccurred'))
          } else if (data?.url) {
            window.location.href = data.url
          }
        }
      }
    )
  }

  const isUserHrPlus = user?.isPureUserHRPlusOrAbove
  const isDeferMigrationEnabled = useFeature(
    'ec-integrations-enable-defer-user-driven-migration'
  )
  const isHrPlusDeferMigrationEnabled = useFeature(
    'ec-integrations-enable-hr-plus-defer-udm'
  )

  const canDefer =
    isDeferMigrationEnabled && (isHrPlusDeferMigrationEnabled || !isUserHrPlus)

  return (
    <StartView
      loading={loading}
      onContinue={handleContinue}
      onDeferMigration={handleDeferMigration}
      canDefer={canDefer}
    />
  )
}
