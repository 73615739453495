import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { App } from './app/App'
import './index.css'
import { setupSentry } from '@toasttab/ec-sentry'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-udm',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer']
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'team-user-migration-spa'

setupSentry({
  spa: name,
  dsn: 'https://75d32d36f4b3b76d28eb61910687d1c0@o37442.ingest.us.sentry.io/4508138721968128'
})
